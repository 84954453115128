import MessageInteractive from '@/components/Whatsapp/MessageInteractive/MessageInteractive.vue'
import ModelMessageInteractive from '@/models/whatsapp/MessageInteractive'
import MessageInteractivePreview from '@/components/Whatsapp/MessageInteractivePreview/MessageInteractivePreview.vue'
import UserInboxRatingService from '@/services/whatsapp/user-inbox-rating.service.js'
import { UserInboxRating } from '@/models/whatsapp/UserInboxRating'
import AiService from '@/services/ai/ai.service'
import { Agent } from '@/classes/chat/agent'
import { UserConfig } from '@/models/UserConfig'
import { UserConfigIdentifier } from '@/classes/UserConfigIdentifier.js'

import EventBus from '@/util/EventBus'

export default {
  name: 'Rate',
  components: {
    MessageInteractive,
    MessageInteractivePreview,
  },
  props: {
    channel: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      interactiveMessage: new ModelMessageInteractive(),
      dialogRating: false,
      selectedRating: null,
      ratingOptions: [],
      ratings: [],
      page: 1,
      userInboxRating: new UserInboxRating(),
      selectedAgent: null,
      agents: [],
      removeDialog: false,
      loading: false,
      userInboxAgenRatings: [],
      readyAgents: false,
      readyRatings: false,
      renderInteractiveComponent: false,
      selectedFrecuency: undefined,
      enabled: false,
    }
  },
  computed: {
    ratingChannelHeaderText () {
      return this.channel === 1 ? this.$t('WhatsApp Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.') : this.$t('WebChat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.')
    },
    userInboxAgenRatingsReadable () {
      const result = []
      if (!this.readyAgents && !this.readyRatings) return result

      this.userInboxAgenRatings.forEach(uiar => {
        result.push({
          name: this.ratings.length > 0 ? this.ratings.find(r => r.id === uiar.rating_id)?.name : '',
          agentName: this.agents.length > 0 ? this.agents.find(a => a.id === uiar.agent_id && a.typeId === uiar.agent_type_id).name : '',
          typeId: uiar.agent_type_id,
          agentId: uiar.agent_id,
          ratingId: uiar.rating_id,
          frecuency: this.frecuencies.find(f => f.value === uiar.frecuency)?.text || '',
        })
      })
      return result
    },
    headers () {
      return [
        { text: this.$t('Nombre'), value: 'name', sortable: true },
        { text: this.$t('Fecha'), value: 'created_at', sortable: true },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    userInboxAgenRatingsReadableHeaders () {
      return [
        { text: this.$t('Valoración'), value: 'name', sortable: true },
        { text: this.$t('Agente'), value: 'agentName', sortable: true },
        { text: this.$t('Frecuencia'), value: 'frecuency', sortable: true },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    frecuencies () {
      return [
        { text: this.$t('Sólo 1 vez'), value: null },
        { text: this.$t('1 dia'), value: 1 },
        { text: this.$t('1 semana'), value: 7 },
        { text: this.$t('1 mes'), value: 30 },
      ]
    },
  },
  watch: {
  },
  methods: {
    getEnabledValue () {
      const user = JSON.parse(localStorage.getItem('user'))
      const identifier = this.channel === 1 ? UserConfigIdentifier.WHATSAPP_INBOX_RATING_ENABLED : UserConfigIdentifier.WEBCHAT_INBOX_RATING_ENABLED
      if (!user.config) {
        user.config = {}
        user.config[identifier] = this.enabled
        localStorage.setItem('user', JSON.stringify(user))
        return
      }
      // eslint-disable-next-line no-prototype-builtins
      const value = user.config.hasOwnProperty(identifier) ? user.config[identifier] : false

      if (value !== this.enabled) {
        this.enabled = value
      }
    },

    saveEnableConfigRatingByChannel () {
      const identifier = this.channel === 1 ? UserConfigIdentifier.WHATSAPP_INBOX_RATING_ENABLED : UserConfigIdentifier.WEBCHAT_INBOX_RATING_ENABLED;
      UserConfig.setValueByIdentifier(
        {
          identifier: identifier,
          value: this.enabled,
        },
        this.$t('Se han guardado los cambios con éxito.'),
        this.$t('Se ha producido un error'),
      )
    },

    getAgents () {
      this.assistants = []
      AiService.getAssistantsSimpleList()
      .then(res => {
        const firstAllAgentOption = new Agent({ id: 0, name: 'Todos', typeId: 0 })
        this.agents.push(firstAllAgentOption)

        res.forEach(agent => {
          const agentObject = new Agent(agent)
          this.agents.push(agentObject)
        })
        setTimeout(() => {
          this.readyAgents = true
        })
      })
    },

    createRating () {
      this.userInboxRating = new UserInboxRating()
      this.userInboxRating.channel = this.channel
      this.interactiveMessage = new ModelMessageInteractive()
      this.renderInteractiveComponent = true
      setTimeout(() => {
        this.dialogRating = true
      }, 200)
    },

    viewItem (item) {
      this.userInboxRating.loadData(item)
      this.interactiveMessage = new ModelMessageInteractive()
      this.interactiveMessage.loadData(this.userInboxRating.interactiveMessageData)
      this.renderInteractiveComponent = true
      setTimeout(() => {
        this.dialogRating = true
      }, 200)
    },


    getData () {
      UserInboxRatingService.getData({ channel: this.channel }).then((response) => {
        this.ratings = response
        this.loading = false
        this.removeDialog = false
        this.readyRatings = true
      })
    },

    create () {
      const saveUserInboxRating = this.userInboxRating
      saveUserInboxRating.interactiveMessageData = JSON.stringify(this.interactiveMessage)
      UserInboxRatingService.create(saveUserInboxRating).then((response) => {
        this.page = 1
        this.getData()
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios.'))

        this.dialogRating = false
        setTimeout(() => {
          this.renderInteractiveComponent = false
        }, 1000)
      })
    },

    deleteItem (item) {
      this.userInboxRating.loadData(item)
      this.removeDialog = true
    },

    async confirmRemove () {
      this.loading = true
      UserInboxRatingService.remove({ id: this.userInboxRating.id }).then(() => {
        this.page = 1
        this.getData()

        this.userInboxAgenRatings = this.userInboxAgenRatings.filter(rating => {
          return rating.rating_id !== this.userInboxRating.id
        })
        this.createRecords()
      })
    },


    createRecords () {
      EventBus.$emit('hideAlert')
      return UserInboxRatingService.createRecords({ data: this.userInboxAgenRatings, channel: this.channel }).then(() => {
        this.allByCurrentUser()
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios.'))
      })
    },

    allByCurrentUser () {
      return UserInboxRatingService.allByCurrentUser({ channel: this.channel }).then((response) => {
        this.userInboxAgenRatings = response
      })
    },

    addRating () {
      const existsAgent = this.userInboxAgenRatings.some(rating => rating.agent_id === this.selectedAgent.id && rating.agent_type_id === this.selectedAgent.typeId)

      if ((this.selectedAgent.id === 0 && this.selectedAgent.typeId === 0 && existsAgent) || existsAgent) {
        return
      }

      if (!this.userInboxAgenRatings.some(rating => rating.rating_id === this.selectedRating && rating.agent_id === this.selectedAgent.id && rating.agent_type_id === this.selectedAgent.typeId)) {
        this.userInboxAgenRatings.push({
          rating_id: this.selectedRating,
          agent_id: this.selectedAgent.id,
          agent_type_id: this.selectedAgent.typeId,
          frecuency: this.selectedFrecuency,
        })
      }
      this.createRecords()
    },

    removeAgentRating (typeId, agentId, ratingId) {
      this.userInboxAgenRatings = this.userInboxAgenRatings.filter(rating => {
        return !(rating.agent_type_id === typeId && rating.agent_id === agentId && rating.rating_id === ratingId)
      })
      this.createRecords()
    },
  },
  mounted () {
    console.log(UserConfig)
    this.userInboxRating.channel = this.channel
    this.getData()
    this.getAgents()
    this.allByCurrentUser()
    this.getEnabledValue()
  },
}

