import { Service } from '@/services/service'

const PATH = 'whatsapp/rating/'

class UserInboxRatingService extends Service {
  getData (params) {
    return this.get(PATH + 'data', params)
  }

  create (params) {
    return this.post(PATH + 'create', params)
  }

  remove (params) {
    return this.post(PATH + 'remove', params)
  }


  createRecords (params) {
    return this.post(PATH + 'create-records', params)
  }

  allByCurrentUser (params) {
    return this.post(PATH + 'all-by-current-user', params)
  }
}

export default new UserInboxRatingService()
