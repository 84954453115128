import Tags from '@/components/WebChat/Tags/Tags.vue'
import Rate from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/InboxConfig/Rate/Rate.vue'

export default {
    name: 'InboxConfig',
    components: {
        Tags,
        Rate,
    },
    props: { },
    data () {
        return {
            selectedComponent: null,
        }
    },
    watch: {

    },
    computed: {
        actions () {
            return [
                { id: 'tags', text: this.$t('Etiquetas').toString(), icon: 'fas fa-tags' },
                { id: 'rating-config', text: this.$t('Valoraciones').toString(), icon: 'fas fa-star' },
            ]
        },
    },
    mounted () {
        this.selectedComponent = this.actions[0].id
    },
    methods: { },
}
