import { Service } from './service'

const PATH = 'config'

class UserConfigService extends Service {
  setValueByIdentifier (params = {}) {
      return this.post(PATH + '/set-value-by-identifier', params)
  }
}

export default new UserConfigService()
