import EventBus from '../util/EventBus'
import UserConfigService from '@/services/user.config.service.js'

export class UserConfig {
  static setValueByIdentifier (params, messageSuccess, messageError) {
    EventBus.$emit('hideAlert')
    UserConfigService.setValueByIdentifier(params).then(() => {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user.config) {
        user.config = {}
      }
      user.config[params.identifier] = params.value
      localStorage.setItem('user', JSON.stringify(user))
      EventBus.$emit('showAlert', 'success', messageSuccess)
    }).catch(() => {
      EventBus.$emit('showAlert', 'danger', messageError)
    })
  }
}
